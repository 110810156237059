<template src='./activityQuickTestInsight.html'>
</template>

<script>
import inputContainer from '../../Components/inputContainer'
import { Bar } from 'vue-chartjs'
import VueBarGraph from 'vue-bar-graph'
// import showStatus from '../../NetworkManager/showStatus'
import LectureRepository from '../../Repository/lectures'
import UserRepository from '../../Repository/User'
import SemesterUserRepository from '../../Repository/SemesterUser'
import ExamSubjectRepository from '../../Repository/ExamSubject'
import ExamStudentRepository from '../../Repository/ExamStudent'
import apiV3 from '../../NetworkManager/apiV3'
import { createExcelThroughJson } from '../../utils/excel';

export default {
  name: 'activityQuickTestInsight',
  components: {
    inputContainer,
    VueBarGraph
  },
  props: [
    'prop_subject'
  ],
  // extends: Bar,
  mixins: [Bar],
  data() {
    return {
      selectedExam: {},
      graphDataLoaded: false,
      upperlimitsArray: [],
      series: [{
        name: 'Marks Distribution',
        data: []
        // data: [44, 55, 41, 67, 22]
      }],
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          height: 350,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            columnWidth: '75%'
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          title: {
            text: 'Marks'
          },
          labels: {
          },
          categories: []
        },
        yaxis: {
          title: {
            text: 'Nummber of Students'
          }
        },
        fill: {
          colors: ['#050992']
        }
      },
      datacollection: {
        labels: ['January'],
        datasets: [
          {
            label: 'Data one',
            backgroundColor: '#f87979',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            data: [40]
          }
        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }]
        },
        responsive: true,
        height: 200
      },
      isMobileView: false,
      totalStudents: 0,
      totalLectures: 0,
      selectedLimit: 75,
      defaulterStudentsPercent: 0,
      defaulterStudentsCount: 0,
      failedStudentCount: 0,
      passedStudentCount: 0,
      failedStudentPercent: 0,
      passedStudentPercent: 0,
      nondefaulterStudentsPercent: 0,
      nondefaulterStudentsCount: 0,
      search: '',
      studentsList: [],
      headers: [
        {
          text: '',
          value: 'absentStatusDot'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Marks',
          value: 'marks'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Answers',
          value: 'answers'
        }
      ],
      selectedSubject: {},
      isAnswersDialogOpen: false,
      selectedStudent: null,
      isLoadingAnalysis: false,
      isLoadingAnswers: false,
      studentAnswers: [],
      attemptNo: 0,
      attemptData: {},
      questions: 0,
      time: 0,
      responseLength: 0,
      timeTake: '00:00',
      count: 0,
      actualAnswer: []
    }
  },
  created() {
    this.selectedExam = this.$store.getters['liveData/get_selectedExam']
    this.selectedExam.totalObtainableMarks = this.selectedExam.totalMarks ? this.selectedExam.totalMarks : 100
    this.selectedLimit = this.selectedExam.passingPercent
    this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    this.examSubjectRepositoryInstance = new ExamSubjectRepository(this)
    this.examStudentRepositoryInstance = new ExamStudentRepository(this)
    if (this.prop_subject) {
      this.selectedSubject = JSON.parse(JSON.stringify(this.prop_subject))
    } else {
      this.selectedSubject = JSON.parse(JSON.stringify(this.$store.getters['liveData/get_selectedSubject']))
    }
    this.$store.commit('liveData/set_selectedActivityName', 'Exam Analysis')
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.lectureRepositoryInstance = new LectureRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.fetchAnalysis()
  },
  methods: {
    async fetchAnalysis() {
      try {
        this.isLoadingAnalysis = true
        const { data } = await apiV3.getRequest(`/exam/analysis?examId=${this.selectedExam._id}`)
        let promises = []
        this.selectedExam.allowedStudents.forEach((id) => {
          promises.push(this.userRepositoryInstance.getFullNameOfUser({ uId: id }))
        })
        promises = await Promise.allSettled(promises)
        const temp = []
        for (let i = 0; i < promises.length; i++) {
          temp.push({
            name: promises[i].value.replace("  ", " "),
            _id: this.selectedExam.allowedStudents[i]
          })
        }
        const merged = []
        for (let i = 0; i < temp.length; i++) {
          /* Thanks for merge logic: https://stackoverflow.com/a/46849389/18870132 */
          const allData = data.find((answer) => answer.studentId === temp[i]._id)
          if (allData) {
            const { totalMarksObtained, status, ...rest } = allData
            if (status === 'pass') {
              this.passedStudentCount++
            } else {
              this.failedStudentCount++
            }
            merged.push({
              ...temp[i],
              marks: totalMarksObtained,
              status,
              ...rest
            });
          } else {
            this.failedStudentCount++
            merged.push({
              ...temp[i],
              status: 'absent',
              marks: '-'
            });
          }
        }
        this.studentsList = merged
        this.failedStudentPercent = ((this.failedStudentCount / this.selectedExam.allowedStudents.length) * 100).toFixed(0)
        this.passedStudentPercent = ((this.passedStudentCount / this.selectedExam.allowedStudents.length) * 100).toFixed(0)
        this.studentList = data
        this.findCategory()
        this.actualAnswer = []
        const values = await apiV3.getRequest(
          `/exam/getQuestionsByExam?examId=${this.selectedExam._id}`
        );
        this.actualAnswer = values.questions
        // console.log('this.actualAnswer', this.actualAnswer);
      } catch (err) {
        console.log(err)
        this.analysisData = []
      } finally {
        this.isLoadingAnalysis = false
      }
    },
    async changeLimit() {
      // console.log('this is changed limit', this.selectedLimit)
      if (this.selectedLimit === '' || this.selectedLimit < 0) {
        this.selectedLimit = 1
      }
      if (this.selectedLimit > 100) {
        this.selectedLimit = 100
      }
      if (isNaN(this.selectedLimit)) {
        this.selectedLimit = 0
      }
      const selectedLimitInNumbers = (this.selectedLimit * parseInt(this.selectedExam.totalObtainableMarks ? this.selectedExam.totalObtainableMarks : 100)) / 100
      // console.log('this is selectedlimitsinnumbers', selectedLimitInNumbers)
      this.failedStudentCount = 0
      this.passedStudentCount = 0
      // console.log('this is students list', this.studentsList)
      for (let i = 0; i < this.studentsList.length; i++) {
        if (parseInt(this.studentsList[i].marks) < parseInt(selectedLimitInNumbers)) {
          this.studentsList[i].status = 'Failed'
          this.failedStudentCount++
        } else {
          this.studentsList[i].status = 'Passed'
          this.passedStudentCount++
        }
      }
      this.failedStudentPercent = ((this.failedStudentCount / this.studentsList.length) * 100).toFixed(0)
      this.passedStudentPercent = ((this.passedStudentCount / this.studentsList.length) * 100).toFixed(0)
    },
    async findCategory() {
      let lowerLimits = 0
      const difference = Math.ceil(parseInt(this.selectedExam.totalObtainableMarks) / 5)
      let upperLimits = difference
      while (lowerLimits <= this.selectedExam.totalObtainableMarks) {
        this.upperlimitsArray.push(upperLimits)
        this.series[0].data.push(0)
        this.chartOptions.xaxis.categories.push(lowerLimits + '-' + upperLimits)
        lowerLimits = upperLimits
        upperLimits = lowerLimits + difference
        // this.marksPerCategory(lowerLimits, upperLimits)
      }
      this.marksPerCategory()
    },
    async marksPerCategory() {
      for (let i = 0; i < this.studentsList.length; i++) {
        for (let j = 0; j < this.upperlimitsArray.length; j++) {
          if (this.upperlimitsArray[j] > parseInt(this.studentsList[i].marks)) {
            this.series[0].data[j]++
            break
          }
        }
      }
      this.graphDataLoaded = true
    },
    async downloadPdf() {
      try {
        const pdfBlob = await this.lectureRepositoryInstance.generateAttendanceAnalysisExcel(this.prop_subject)
        const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(pdfBlob2)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Attendance Report.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }
    },
    calculateAttempt(i) {
      // Assuming prop_exam is an array containing the data for each attempt.
      this.count = 0
      this.time = '00:00'
      this.attemptData = {}
      if (this.studentAnswers[i - 1]) {
        this.count = 0
        this.attemptData = this.studentAnswers[i - 1];
        // console.log('this.count', this.attemptData)
        if (this.actualAnswer.length > 0 && Array.isArray(this.attemptData.answers)) {
          this.actualAnswer.map((item, i) => {
            this.attemptData.answers.map((ans) => {
              if (ans.question === item.question) {
                ans.index = i
              }
            })
          })
          this.attemptData.answers = this.attemptData.answers.sort((a, b) => a.index - b.index);
          console.log('this.attemptData.answers', this.attemptData.answers);
        }
        for (let j = 0; j < this.attemptData?.answers.length; j++) {
          if (this.attemptData?.answers[j].answer === this.attemptData?.answers[j].selectedAnswer) this.count++
        }
        // console.log('attemptData1', this.attemptData)

        this.questions = this.attemptData.answers.length
      }
    },
    async showAnswers(student) {
      try {
        this.isAnswersDialogOpen = true
        this.isLoadingAnswers = true
        this.selectedStudent = student
        const answers = await apiV3.postRequest(`/exam/getAllAnswers`, {
          studentId: `${student._id}`,
          examIds: [`${this.selectedExam._id}`]
        });
        const newAnsArr = []
        if (answers.length > 3) {
          for (let i = 0; i < 3; i++) {
            newAnsArr.push({ ...answers[i] })
          }
          this.studentAnswers = newAnsArr
        } else {
          this.studentAnswers = answers
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoadingAnswers = false
      }
    },
    clearFields() {
      this.isAnswersDialogOpen = false
      this.selectedStudent = null
      this.attemptNo = 0
      this.attemptData = {}
      this.questions = 0
      this.time = 0
      this.responseLength = 0
      this.timeTake = '00:00'
      this.count = 0
    },
    async exportQuestionsExcel() {
      try {
        const res = this.studentsList.map(item => item._id);
        const excelData = await apiV3.postRequest(`/exam/getAllAnswersBulk`, {
          studentIds: res,
          examIds: [`${this.selectedExam._id}`]
        });
        const studentIds = excelData.map(student => student.studentId);
        const answersArr = excelData.map(answer => answer.answers);

        const data = this.studentsList.map((student) => {
          const index = studentIds.indexOf(student._id);
          if (index !== -1) {
            const obj = {
              Name: student.name,
              Status: student.status,
              Marks: student.marks,
              'Total Marks': student.totalOutOf
            };
            if (this.actualAnswer.length > 0 && Array.isArray(answersArr[index])) {
          this.actualAnswer.map((item, i) => {
            answersArr[index].map((ans) => {
              if (ans.question === item.question) {
                ans.index = i
              }
            })
          })
          answersArr[index] = answersArr[index].sort((a, b) => a.index - b.index);
          // console.log('this.attemptData.answers', this.attemptData.answers);
        }
            answersArr[index].forEach((answer, i) => {
              if (answer.marksObtained === undefined || answer.outOf === undefined) {
                if (this.actualAnswer.length > 0) {
                  this.actualAnswer.map((item) => {
                    if (item.question === answer.question) {
                      if (item.answer === answer.answer) {
                        obj[`Question${i + 1}`] = `${item.marks} / ${item.marks}`;
                      } else {
                        obj[`Question${i + 1}`] = `0 / ${item.marks}`;
                      }
                    }
                  })
                }
              } else {
                obj[`Question${i + 1}`] = `${answer.marksObtained} / ${answer.outOf}`;
              }
            });
            return obj;
          }
        }).filter(Boolean);

        createExcelThroughJson(data, 'Quick Test Analysis');
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }

  }
}
</script>

<style src='./activityQuickTestInsight.css'></style>
